import axios from 'axios'
import {
    Toast
} from 'vant'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_BASE_API,
    // 超时
    timeout: 300000
})

// request拦截器
service.interceptors.request.use(
    config => {
        console.log(process.env);
        // if (getToken()) {
        //   config.headers['Authorization'] = 'Bearer ' + '' // 让每个请求携带自定义token 请根据实际情况自行修改
        // }
        if (!config.headers.agent) {
            config.headers['agent'] = 'wap'
        }
        return config
    },
    error => {
        console.log(error)
        Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(res => {
        const code = res.data.code
        //1003 - 该手机号已被绑定
        if (code == 0 && code == 1003 && code == 19 &&code == 20) {
            Toast.fail(res.data.message);
            // Loading.service().close()
            return Promise.reject('error')
        } else {
            return res.data
        }
    },
    error => {
        console.log('err' + error)
        Toast.fail(error.message);
        return Promise.reject(error)
    }
)

export default service
